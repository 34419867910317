// Here you can add other styles

body{
    background-color:"red"
  }

[type="file"] {
  height: 0;
  overflow: hidden;
  width: 0;
}

[type="file"] + label {
  background: #0061f2;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
	font-family: 'Rubik', sans-serif;
	font-size: inherit;
  font-weight: 500;
  margin-bottom: 1rem;
  outline: none;
  padding: 15px 20px;
  position: relative;
  transition: all 0.3s;
  vertical-align: middle;
  margin-top: 2rem;
  
  &:hover {
    background-color: darken(#0061f2, 10%);
  }
}